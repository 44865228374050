<!-- @format -->
<template>
  <div class="trailer-dummy">
    <div
      class="trailer-dummy__image"
      @click="onClick"
      :style="`margin: ${margin}px; height: ${height}px; width: ${width}px;`"
    ></div>
    <div class="trailer-dummy__text">
      {{ $t('trailer.text') }}
    </div>
  </div>
</template>

<script>
  import { EventBus } from '@/plugins/event-bus.js'

  export default {
    props: {
      margin: {
        type: Number,
        default: 0,
      },
      height: {
        type: Number,
        default: 0,
      },
      width: {
        type: Number,
        default: 0,
      },
    },
    methods: {
      onClick() {
        EventBus.$emit('open-real')
      },
    },
  }
</script>

<style lang="scss">
  .trailer-dummy {
    cursor: pointer;
    height: 100vh;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    &__image {
      background: #000 url($assets + 'images/trailer/dummy.jpg') center center
        no-repeat;
      background-size: contain;
    }
    &__text {
      position: absolute;
      width: 100%;
      left: 50%;
      top: 40px;
      transform: translateX(-50%);
      color: white;
    }
  }
</style>
